(function () {
  'use strict';

  class GalleryCtrl {
    constructor($mdDialog, Media, $scope) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Media = Media;
      vm.init();
      $scope.$watch('gallery.media', () => {
        if (vm.media.file && vm.media.file.type.match('image.*')) {
          delete vm.gallery;
          vm.uploadMedia(vm.media.file);
        }
      });
    }
    init() {
      let vm = this;
      vm.Media.mediaIndex()
        .then((data)=> {
          vm.gallery = data;
        });
    }
    uploadMedia(file) {
      const vm = this;
      vm.Media.mediaCreate(file)
        .then(()=>{
          vm.init();
        });
    }
    select(index, photo) {
      let vm = this;
      vm.selected = index;
      vm.photo = photo;
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide() {
      let vm = this;
      vm.$mdDialog.hide(vm.photo);
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:GalleryCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('GalleryCtrl', GalleryCtrl);
}());
